import { useState } from 'react';
import { includes, isEqual } from 'lodash';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { useWindow } from 'hooks/useWindow';
import Styled from './styles';
import MobileNavigation from './mobileNavigation';
import { HOME_ROUTE, HOME_VALUATION_ROUTE } from 'constants/routes';
import messages from './messages';
import { ListingData } from 'containers/listing/slice/types';
import { BLOG_LINK, MANIFESTO_LINK } from 'constants/links';
import { listingStatuses } from 'containers/county-form/constants';
import { listingSelector } from 'containers/listing/slice/selector';
import { useAppSelector } from 'hooks/useRedux';
import useTriggerFunnelyticsEvent from 'hooks/useTriggerFunnelyticsEvent';
import {
  GENERIC_EVENTS,
  HOME_VALUATION_EVENTS,
} from 'hooks/useTriggerFunnelyticsEvent/constants';
import { CALL_EXPERT_LINK, media, SELLER_LISTING_ROUTE } from './constants';

function HomepageHeader({ isWhiteLinks }: HomePageHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { w: width } = useWindow();
  const { createEventTriggerer } = useTriggerFunnelyticsEvent();
  const { asPath } = useRouter();
  const { data: listingData } = useAppSelector(listingSelector);
  const { status: listingStatus } = (listingData as ListingData) || {};
  const isSticky =
    includes(asPath, SELLER_LISTING_ROUTE) &&
    isEqual(listingStatus, listingStatuses.sellerAnswering);

  const richrLogo = isWhiteLinks ? media.logoWhite : media.logo;

  function handleNavClick() {
    setIsOpen((prevState) => !prevState);
  }

  function renderHeaderDesktop() {
    return (
      <>
        <Styled.HeaderNavLeft isWhiteLinks={isWhiteLinks}>
          <NextLink href={HOME_ROUTE} passHref>
            <a>
              <NextImage
                src={richrLogo}
                width={width <= 1280 ? 112 : 123}
                height={width <= 1280 ? 38 : 41}
              />
            </a>
          </NextLink>
          <NextLink href={MANIFESTO_LINK} passHref>
            <a target="_blank" className="header-link">
              <FormattedMessage {...messages.manifesto} />
            </a>
          </NextLink>
          <NextLink href={BLOG_LINK} passHref>
            <a target="_blank" className="header-link">
              <FormattedMessage {...messages.blog} />
            </a>
          </NextLink>
          <a
            href={CALL_EXPERT_LINK}
            className="call-an-expert"
            onClick={createEventTriggerer({
              eventName: GENERIC_EVENTS.CALL_EXPERT,
              withPathname: true,
              ctaPosition: 'nav-bar',
            })}
          >
            <FormattedMessage {...messages.callExpert} />
            <NextImage src={media.rightArrow} height={20} width={20} />
          </a>
        </Styled.HeaderNavLeft>
        <Styled.HeaderNavRight isWhiteLinks={isWhiteLinks}>
          <NextLink href={HOME_VALUATION_ROUTE} passHref>
            <a
              onClick={createEventTriggerer({
                eventName: HOME_VALUATION_EVENTS.START,
                withPathname: true,
                ctaPosition: 'nav-bar',
              })}
            >
              <FormattedMessage {...messages.valuation} />
            </a>
          </NextLink>
        </Styled.HeaderNavRight>
      </>
    );
  }

  function renderHeaderMobile() {
    return (
      <>
        <MobileNavigation isOpen={isOpen} />
        <Styled.HeaderMobileWrapper isOpen={isOpen} isWhiteLinks={isWhiteLinks}>
          <NextLink href={HOME_ROUTE} passHref>
            <a>
              <NextImage
                src={richrLogo}
                width={72}
                height={24}
                className="header-logo-mobile"
              />
            </a>
          </NextLink>
          <div
            onClick={handleNavClick}
            id="nav-icon"
            className={cx({ open: isOpen })}
          >
            <span />
            <span className="nav-icon-middle" />
            <span />
          </div>
        </Styled.HeaderMobileWrapper>
      </>
    );
  }

  return (
    <Styled.HeaderNav className="header-nav" isSticky={isSticky}>
      {width > 768 ? renderHeaderDesktop() : renderHeaderMobile()}
    </Styled.HeaderNav>
  );
}

interface HomePageHeaderProps {
  isWhiteLinks?: boolean;
}

export default HomepageHeader;
