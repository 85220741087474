import NextImage from 'next/image';
import NextLink from 'next/link';
import { FormattedMessage } from 'react-intl';

import { HOME_ROUTE, HOME_VALUATION_ROUTE } from 'constants/routes';
import Styled from './styles';
import messages from './messages';
import { CALL_EXPERT_LINK, media } from './constants';
import { BLOG_LINK, MANIFESTO_LINK } from 'constants/links';
import useTriggerFunnelyticsEvent from 'hooks/useTriggerFunnelyticsEvent';
import {
  GENERIC_EVENTS,
  HOME_VALUATION_EVENTS,
} from 'hooks/useTriggerFunnelyticsEvent/constants';

function MobileNavigation({ isOpen }: MobileNavigationProps) {
  const { createEventTriggerer } = useTriggerFunnelyticsEvent();

  function renderNavbarMobile() {
    return (
      <Styled.NavItems className="disable-body-scroll">
        <div className="nav-items-logo">
          <NextLink href={HOME_ROUTE} passHref>
            <a>
              <NextImage src={media.logo} height={36} width={106} />
            </a>
          </NextLink>
        </div>
        <div className="nav-item">
          <NextLink href={MANIFESTO_LINK} passHref>
            <a>
              <FormattedMessage {...messages.manifesto} />
            </a>
          </NextLink>
        </div>
        <div className="nav-item">
          <NextLink href={BLOG_LINK} passHref>
            <a>
              <FormattedMessage {...messages.blog} />
            </a>
          </NextLink>
        </div>
        <Styled.HeaderNavRight>
          <NextLink href={HOME_VALUATION_ROUTE} passHref>
            <a
              className="nav-valuation-btn"
              onClick={createEventTriggerer({
                eventName: HOME_VALUATION_EVENTS.START,
                withPathname: true,
                ctaPosition: 'mobile-nav-bar',
              })}
            >
              <FormattedMessage {...messages.valuation} />
            </a>
          </NextLink>
        </Styled.HeaderNavRight>
        <div className="nav-bottom">
          <p>
            <FormattedMessage {...messages.wantToTalk} />
          </p>
          <a
            href={CALL_EXPERT_LINK}
            onClick={createEventTriggerer({
              eventName: GENERIC_EVENTS.CALL_EXPERT,
              withPathname: true,
              ctaPosition: 'mobile-nav-bar',
            })}
          >
            <FormattedMessage {...messages.callExpert} />
            <NextImage src={media.phone} height={20} width={20} />
          </a>
        </div>
      </Styled.NavItems>
    );
  }

  return (
    <Styled.MobileNavigationWrapper isOpen={isOpen}>
      <div className="nav-mobile-menu">{isOpen && renderNavbarMobile()}</div>
    </Styled.MobileNavigationWrapper>
  );
}

interface MobileNavigationProps {
  isOpen: boolean;
}

export default MobileNavigation;
