import styled, { css } from 'styled-components';

import { defaultTheme, defaultTheme as theme } from 'styles/theme';
import { device } from 'styles/breakpoints';

const {
  typography: { fontWeights, fontFamily },
  colors,
} = theme;

export const CustomTypographyVariantValues = [
  'body',
  'bodyMedium',
  'bodyMediumRegular',
  'body1',
  'body2',
  'body3',
  'body4',
  'body5',
  'h4One',
] as const;

export type CustomTypographyVariants =
  (typeof CustomTypographyVariantValues)[number];

export type FontWieghts = keyof typeof theme.typography.fontWeights;

export interface StyledTypographyProps {
  variant?: keyof JSX.IntrinsicElements | CustomTypographyVariants;
  fontWeight?: FontWieghts;
  color?: keyof typeof defaultTheme.colors;
}

const heading1Styles = css`
  font-size: 45px;
  line-height: 1.21;
  font-weight: ${fontWeights.semibold};

  @media ${device.tablet} {
    font-size: 25px;
  }
`;

const heading2Styles = css`
  font-size: 40px;
  line-height: 1.21;
  font-weight: ${fontWeights.bold};

  @media ${device.tablet} {
    font-size: 25px;
  }
`;

const heading3Styles = css`
  font-size: 32px;
  font-weight: ${fontWeights.semibold};
  line-height: 1.21;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.2;
  }
`;

const heading4Styles = css`
  font-size: 25px;
  font-weight: ${fontWeights.medium};
  line-height: 1.2;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 1.31;
  }
`;

const heading4OneStyles = css`
  ${heading4Styles}
  font-family: ${fontFamily.walsheimProMedium};

  @media ${device.tablet} {
    font-size: 22px;
  }
`;

const heading5Styles = css`
  font-size: 22px;
  line-height: 1.22;
  font-weight: ${fontWeights.regular};

  @media ${device.tablet} {
    font-size: 17px;
    line-height: 1.35;
  }
`;

const heading6Styles = css`
  font-size: 20px;
  font-weight: ${fontWeights.semibold};
  line-height: 1.2;

  @media ${device.tablet} {
    font-size: 17px;
    line-height: 1.35;
  }
`;

const bodyMediumStyles = css`
  font-size: 17px;
  line-height: 1.35;
  font-weight: ${fontWeights.medium};
  color: ${theme.colors.blackPallet6};

  @media ${device.tablet} {
    font-size: 14px;
    font-weight: ${fontWeights.regular};
  }
`;

const bodyMediumRegularStyles = css`
  font-size: 20px;
  font-weight: ${fontWeights.regular};
  line-height: 1.2;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 1.31;
    text-align: center;
  }
`;

const body1Styles = css`
  font-size: 14px;
  line-height: 1.35;
  text-align: center;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 1.41;
  }
`;

const body2Styles = css`
  font-size: 16px;
  line-height: 1.31;
  text-align: center;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 1.41;
  }
`;

const body3Styles = css`
  font-size: 12px;
  line-height: 1.41;
`;

const body4Styles = css`
  font-size: 17px;
  line-height: 1.35;
  text-align: center;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 1.41;
  }
`;

const body5Styles = css`
  font-size: 14px;
  line-height: 1.35;
`;

const bodyStyles = css`
  font-size: 17px;
  line-height: 1.35;
  font-weight: ${fontWeights.regular};

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const Typography = styled.p<StyledTypographyProps>`
  ${({ variant }) => {
    switch (variant) {
      case 'h1':
        return heading1Styles;
      case 'h2':
        return heading2Styles;
      case 'h3':
        return heading3Styles;
      case 'h4':
        return heading4Styles;
      case 'h4One':
        return heading4OneStyles;
      case 'h5':
        return heading5Styles;
      case 'h6':
        return heading6Styles;
      case 'bodyMedium':
        return bodyMediumStyles;
      case 'bodyMediumRegular':
        return bodyMediumRegularStyles;
      case 'body1':
        return body1Styles;
      case 'body2':
        return body2Styles;
      case 'body3':
        return body3Styles;
      case 'body4':
        return body4Styles;
      case 'body5':
        return body5Styles;
      case 'body':
        return bodyStyles;
      default:
        return bodyStyles;
    }
  }}

  ${({ color }) => (color ? `color: ${colors[color]};` : '')}

  ${({ fontWeight }) =>
    fontWeight
      ? css`
          font-weight: ${fontWeights[fontWeight]};
        `
      : null}
`;

export default { Typography };
