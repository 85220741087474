import styled from 'styled-components';

import { device } from 'styles/breakpoints';

const Container = styled.div`
  padding: 100px 70px 60px;
  background-color: ${({ theme }) => theme.colors.blackPallet6};

  .footer-link-title {
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.35;
    color: ${({ theme }) => theme.colors.grayPallet9};
  }

  @media ${device.tablet} {
    padding: 80px 20px 60px;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blackPallet5};
  padding-bottom: 60px;

  .external-links {
    display: flex;
    justify-content: space-between;
    width: 53%;

    .footer-link-title {
      font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
    }

    .footer-link {
      font-size: 17px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
      line-height: 1.35;
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    }

    @media ${device.laptop} {
      width: 70%;
    }
  }

  @media ${device.tablet} {
    flex-direction: column;

    .external-links {
      margin-top: 58px;
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

const Social = styled.div`
  display: flex;
  flex-direction: column;

  .footer-logo {
    margin-bottom: 30px;
  }

  .footer-texts {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-bottom: 40px;
  }

  .follow-us-on {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    color: ${({ theme }) => theme.colors.white};
    line-height: 1.35;

    .social-links {
      display: flex;
      gap: 20px;
    }
  }

  @media ${device.tablet} {
    align-items: center;

    .follow-us-on {
      text-align: center;
    }

    .social-links {
      gap: 25px;
    }

    .footer-texts p {
      text-align: center;
    }
  }
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Resources = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TermsAndPrivacy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;

  @media ${device.mobile} {
    padding-top: 30px;
  }
`;

const Copyright = styled.div`
  .footer-description {
    color: ${({ theme }) => theme.colors.grayPallet18};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blackPallet5};
    font-size: 14px;
    line-height: 1.35;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px 0;

    p {
      text-align: initial;
    }
  }

  .footer-bottom {
    font-size: 14px;
    line-height: 1.35;
    color: ${({ theme }) => theme.colors.grayPallet9};
    padding-top: 30px;
  }

  @media ${device.tablet} {
    .footer-description,
    .footer-bottom {
      font-size: 12px;
      line-height: 1.41;
    }

    .footer-description {
      padding-top: 18px;
    }
  }
`;

const SvgWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.green};
  transition: 0.2s ease-in-out;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    color: ${({ theme }) => theme.colors.green};
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.green};

    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media ${device.tablet} {
    background-color: ${({ theme }) => theme.colors.green};

    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default {
  Container,
  Links,
  Social,
  About,
  Resources,
  TermsAndPrivacy,
  Copyright,
  SvgWrapper,
};
