import NextLink from 'next/link';

import { FACEBOOK_LINK, LINKEDIN_LINK, TWITTER_LINK } from './constants';
import Styled from './styles';

function LinkedIn() {
  return (
    <Styled.SvgWrapper>
      <NextLink href={LINKEDIN_LINK} passHref>
        <a>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              d="M0.285706 2.5425C0.285706 1.88833 0.516018 1.34864 0.976622 0.923451C1.43723 0.49824 2.03603 0.285645 2.773 0.285645C3.49683 0.285645 4.08245 0.494963 4.52991 0.913639C4.99051 1.34538 5.22082 1.90795 5.22082 2.60137C5.22082 3.22937 4.9971 3.75268 4.54965 4.17136C4.08904 4.60311 3.48366 4.81898 2.73352 4.81898H2.71378C1.98996 4.81898 1.40434 4.60311 0.956882 4.17136C0.509424 3.73961 0.285706 3.19665 0.285706 2.5425ZM0.542332 19.7142V6.60484H4.92472V19.7142H0.542332ZM7.35279 19.7142H11.7352V12.3942C11.7352 11.9362 11.7878 11.583 11.8931 11.3344C12.0773 10.8896 12.357 10.5134 12.7321 10.206C13.1071 9.89853 13.5776 9.74481 14.1435 9.74481C15.6175 9.74481 16.3545 10.7326 16.3545 12.7082V19.7142H20.7368V12.1979C20.7368 10.2616 20.2762 8.793 19.355 7.79214C18.4338 6.79127 17.2165 6.29084 15.703 6.29084C14.0053 6.29084 12.6827 7.01696 11.7352 8.46919V8.50844H11.7154L11.7352 8.46919V6.60484H7.35279C7.37911 7.02349 7.39228 8.32527 7.39228 10.5102C7.39228 12.6951 7.37911 15.7631 7.35279 19.7142Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </NextLink>
    </Styled.SvgWrapper>
  );
}

function Facebook() {
  return (
    <Styled.SvgWrapper>
      <NextLink href={FACEBOOK_LINK} passHref>
        <a>
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4126 11.1317L10.9426 7.64209H7.62747V5.37857C7.62747 4.42365 8.08972 3.4923 9.57453 3.4923H11.0827V0.521428C11.0827 0.521428 9.7146 0.285645 8.40723 0.285645C5.67574 0.285645 3.8921 1.95735 3.8921 4.98245V7.64209H0.857117V11.1317H3.8921V19.568C4.50143 19.6647 5.12477 19.7142 5.75978 19.7142C6.3948 19.7142 7.01814 19.6647 7.62747 19.568V11.1317H10.4126Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </NextLink>
    </Styled.SvgWrapper>
  );
}

function Twitter() {
  return (
    <Styled.SvgWrapper>
      <NextLink href={TWITTER_LINK} passHref>
        <a>
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3813 4.69724C18.3895 4.88581 18.3895 5.06581 18.3895 5.25439C18.3976 10.9715 14.2362 17.5716 6.62611 17.5716C4.38158 17.5716 2.178 16.8944 0.285706 15.6258C0.613375 15.6687 0.941045 15.6858 1.26871 15.6858C3.12824 15.6858 4.93862 15.0344 6.40494 13.8258C4.63552 13.7916 3.07909 12.583 2.53843 10.8173C3.16101 10.9458 3.79996 10.9201 4.40615 10.7401C2.48109 10.3458 1.09669 8.57154 1.0885 6.50582C1.0885 6.48868 1.0885 6.47153 1.0885 6.45439C1.66192 6.78868 2.30907 6.97725 2.96441 6.99439C1.15403 5.72582 0.5888 3.19724 1.68649 1.21723C3.79177 3.92581 6.88825 5.56296 10.2141 5.74296C9.87824 4.24295 10.337 2.66581 11.4101 1.60295C13.073 -0.0342027 15.6944 0.0515119 17.2672 1.79152C18.1929 1.60295 19.0858 1.24294 19.8967 0.737228C19.5855 1.74009 18.9383 2.58866 18.0782 3.12866C18.8973 3.02581 19.7001 2.79438 20.4538 2.45152C19.8967 3.32581 19.1922 4.0801 18.3813 4.69724Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </NextLink>
    </Styled.SvgWrapper>
  );
}

export default { LinkedIn, Facebook, Twitter };
