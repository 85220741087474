import { ReactNode } from 'react';
import { includes } from 'lodash';

import Styled, {
  CustomTypographyVariantValues,
  CustomTypographyVariants,
  StyledTypographyProps,
} from './styles';

function Typography({ children, ...props }: TypographyProps) {
  const propsToPass = {
    as: includes(CustomTypographyVariantValues, props.variant)
      ? 'p'
      : props.variant,
    ...props,
    ...('as' in props && { as: props.as }),
  };

  return <Styled.Typography {...propsToPass}>{children}</Styled.Typography>;
}

interface PropsWithoutTag extends StyledTypographyProps {
  children: ReactNode;
  className?: string;
}

interface PropsWithTag extends PropsWithoutTag {
  variant: keyof JSX.IntrinsicElements | CustomTypographyVariants;
  as: keyof JSX.IntrinsicElements;
}

type TypographyProps = PropsWithoutTag | PropsWithTag;

export default Typography;
