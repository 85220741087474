import { RootState } from 'store';

export const listingSelector = (state: RootState) => state.listing;
export const createListingSelector = (state: RootState) =>
  state.listing.createListing;
export const createAdminListingSelector = (state: RootState) =>
  state.listing.createAdminListing;
export const updateListingSelector = (state: RootState) =>
  state.listing.updateListing;
export const deleteListingSelector = (state: RootState) =>
  state.listing.deleteListing;
export const verifyListingTokenSelector = (state: RootState) =>
  state.listing.verifyListingToken;
