import { defineMessages } from 'react-intl';

export const scope = 'app.components.homepage-header';

export default defineMessages({
  manifesto: {
    id: `${scope}.manifesto`,
    defaultMessage: 'Manifesto',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  valuation: {
    id: `${scope}.valuation`,
    defaultMessage: 'Get free home valuation',
  },
  wantToTalk: {
    id: `${scope}.wantToTalk`,
    defaultMessage: 'Want to talk?',
  },
  callExpert: {
    id: `${scope}.callExpert`,
    defaultMessage: 'Call an expert',
  },
});
