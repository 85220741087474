import { includes, isFunction, values } from 'lodash';
import { useRouter } from 'next/router';

import { listingSelector } from 'containers/listing/slice/selector';
import { CreateListingData, ListingData } from 'containers/listing/slice/types';
import { useAppSelector } from 'hooks/useRedux';
import { LISTING_EVENTS } from './constants';

const isAnalyticsDebugEnabled =
  process.env.NEXT_PUBLIC_ANALYTICS_DEBUG === 'true';

function useTriggerFunnelyticsEvent() {
  const { pathname } = useRouter();
  const { data: listingData } = useAppSelector(listingSelector);
  const { seller, address } = (listingData as ListingData) || {};

  function triggerEvent({
    eventName,
    data,
    withPathname,
    ctaPosition,
  }: {
    eventName: string;
    data?:
      | CreateListingData
      | { name: string; address: string; email: string; phone_number: string };
    withPathname?: boolean;
    ctaPosition?: string;
  }) {
    const eventData = {
      // Add listing data for LISTING_EVENTS only
      ...(includes(values(LISTING_EVENTS), eventName) && {
        ...seller,
        ...(address && { address }),
      }),
      ...(withPathname && { pathname }),
      ...(ctaPosition && { ctaPosition }),
      ...(data && data),
    };

    if (isAnalyticsDebugEnabled) {
      // eslint-disable-next-line no-console
      console.log('Funnelytics event triggered', { eventName, eventData });
    }

    (window as any).funnelytics?.events.trigger(eventName, eventData);
  }

  function createEventTriggerer({
    eventName,
    callback,
    data,
    withPathname,
    ctaPosition,
  }: {
    eventName: string;
    callback?: () => any;
    data?: CreateListingData;
    withPathname?: boolean;
    ctaPosition?: string;
  }) {
    return function ctaClickHandler() {
      triggerEvent({ eventName, data, withPathname, ctaPosition });

      if (isFunction(callback)) {
        callback();
      }
    };
  }

  return {
    triggerEvent,
    createEventTriggerer,
  };
}

export default useTriggerFunnelyticsEvent;
