import { useCallback, useEffect, useState } from 'react';

export const useWindow = () => {
  const [w, setW] = useState(window.innerWidth);

  const listener = useCallback((e: Event) => {
    const window = e.currentTarget as Window;

    setW(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [listener]);

  return {
    w,
  };
};

export const useWindowScroll = () => {
  const [scrollTop, setScrollTop] = useState(window.scrollY);

  const listener = useCallback((e: Event) => {
    const window = e.currentTarget as Window;

    setScrollTop(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [listener]);

  return {
    scrollTop,
  };
};
