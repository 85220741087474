import styled, { css } from 'styled-components';

import { device } from 'styles/breakpoints';

const HeaderNav = styled.div<{ isSticky?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 70px;
  position: absolute;
  top: 0;
  width: 100vw;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      z-index: 999;
      background-color: ${({ theme }) => theme.colors.white};
    `}

  @media ${device.laptop} {
    padding: 25px 30px;
  }

  @media ${device.tablet} {
    padding: 0;
  }
`;

const HeaderNavLeft = styled('div')<{ isWhiteLinks?: boolean }>`
  display: flex;
  gap: 80px;
  align-items: center;

  .header-link {
    font-family: ${({ theme }) =>
      theme.typography.fontFamily.walsheimProRegular};
    margin-top: 10px;

    &::after {
      content: '';
      display: block;
      width: 0;
      margin-top: 4px;
      height: 2px;
      background: ${({ theme }) => theme.colors.green};
      transition: width 0.3s ease-in;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .call-an-expert {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.blackPallet6};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
    font-family: ${({ theme }) => theme.typography.fontFamily.Inter};
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    font-size: 17px;
    width: 190px;
    -webkit-font-smoothing: antialiased;

    &:hover {
      opacity: 0.95;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.blackPallet6};
  }

  @media ${device.smallLaptop} {
    gap: 30px;

    .call-an-expert {
      width: initial;
    }
  }

  ${({ isWhiteLinks, theme }) =>
    isWhiteLinks &&
    css`
      .header-link {
        color: ${theme.colors.white};
      }

      .call-an-expert {
        background-color: ${theme.colors.white};
        color: ${theme.colors.blackPallet6};

        &:hover {
          background-color: ${theme.colors.grayPallet20};
        }

        img {
          filter: brightness(0);
        }
      }
    `}
`;

const HeaderNavRight = styled.div<{ isWhiteLinks?: boolean }>`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.colors.green};
    font-family: ${({ theme }) => theme.typography.fontFamily.Inter};
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.green};
    -webkit-font-smoothing: antialiased;
    margin-left: 30px;

    &:hover {
      opacity: 1;
      background-color: ${({ theme }) => theme.colors.greenPallet6};
    }
  }
`;

const HeaderMobileWrapper = styled.div<{
  isOpen: boolean;
  isWhiteLinks?: boolean;
}>`
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div {
    cursor: pointer;
    width: 24px;
    height: 14px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 100;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 18px;
      background: ${({ theme, isWhiteLinks, isOpen }) =>
        !isOpen && isWhiteLinks
          ? theme.colors.white
          : theme.colors.blackPallet6};
      border-radius: 9px;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      right: 0;
    }

    .nav-icon-middle {
      width: 24px;
    }

    span:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 6px;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 12px;
      transform-origin: left center;
    }
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 10px;
    left: 7px;
  }

  .open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -17%;
    left: 7px;
  }
`;

const MobileNavigationWrapper = styled.div<{ isOpen: boolean }>`
  .nav-mobile-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    bottom: 0;
    right: -100vw;
    background-color: ${({ theme }) => theme.colors.white};
    overflow-x: hidden;
    transition: 0.6s;
    width: ${({ isOpen }) => (isOpen ? '100%' : 0)};
    right: ${({ isOpen }) => (isOpen ? 0 : '-100vw')};
  }
`;

const NavItems = styled.div`
  padding: 18px 20px;

  .nav-items-logo {
    margin: 55px 0 35px;
    text-align: center;
  }

  .nav-item {
    padding: 25px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayPallet18};
    text-align: center;
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.352;

    a {
      color: ${({ theme }) => theme.colors.blackPallet6};
    }
  }

  .nav-valuation-btn {
    min-width: 330px;
    padding: 14px 60px;
    margin: 60px 0 30px;
  }

  .nav-bottom {
    padding-top: 30px;
    border-top: 1px solid ${({ theme }) => theme.colors.grayPallet18};
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      margin-bottom: 15px;
      text-align: center;
      color: ${({ theme }) => theme.colors.grayPallet9};
    }

    a {
      min-width: 330px;
      padding: 14px 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: ${({ theme }) => theme.colors.blackPallet6};
      color: ${({ theme }) => theme.colors.white};
      font-size: 17px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
      line-height: 1.35;
    }
  }
`;

export default {
  HeaderNav,
  HeaderNavLeft,
  HeaderNavRight,
  HeaderMobileWrapper,
  MobileNavigationWrapper,
  NavItems,
};
