import { FormattedMessage } from 'react-intl';
import NextImage from 'next/image';
import NextLink from 'next/link';

import { useWindow } from 'hooks/useWindow';
import { media } from './constants';
import messages from './messages';
import Styled from './styles';
import SocialMediaIcons from './socialMediaSvg';
import {
  BLOG_LINK,
  FAQ_LINK,
  MANIFESTO_LINK,
  PRIVACY_LINK,
  TERMS_AND_PRIVACY_LINK,
} from 'constants/links';
import { ABA_ROUTE } from 'constants/routes';
import Typography from 'components/common/typography';

function HomepageFooter() {
  const { w: width } = useWindow();

  function renderSocialLinks() {
    return (
      <div className="external-links">
        <Styled.About>
          <p className="footer-link-title">
            <FormattedMessage {...messages.about} />
          </p>
          <NextLink href={MANIFESTO_LINK} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.manifesto} />
            </a>
          </NextLink>
        </Styled.About>
        <Styled.Resources>
          <p className="footer-link-title">
            <FormattedMessage {...messages.resources} />
          </p>
          <NextLink href={BLOG_LINK} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.blog} />
            </a>
          </NextLink>
          <NextLink href={FAQ_LINK} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.faq} />
            </a>
          </NextLink>
        </Styled.Resources>
        <Styled.TermsAndPrivacy>
          <p className="footer-link-title">
            <FormattedMessage {...messages.termsAndPrivacy} />
          </p>
          <NextLink href={TERMS_AND_PRIVACY_LINK} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.termsAndService} />
            </a>
          </NextLink>
          <NextLink href={PRIVACY_LINK} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.privacyPolicy} />
            </a>
          </NextLink>
          <NextLink href={ABA_ROUTE} passHref>
            <a className="footer-link">
              <FormattedMessage {...messages.aba} />
            </a>
          </NextLink>
        </Styled.TermsAndPrivacy>
      </div>
    );
  }

  function renderCopyrightInfo() {
    return (
      <Styled.Social>
        <div className="footer-logo">
          <NextImage
            src={media.logo}
            width={width <= 1280 ? 112 : 123}
            height={width <= 1280 ? 38 : 41}
          />
        </div>
        <div className="footer-texts">
          <p className="footer-link-title">
            <FormattedMessage {...messages.copyright} />
          </p>
          <p className="footer-link-title">
            <FormattedMessage {...messages.allRightReserved} />
          </p>
        </div>
        <div className="follow-us-on">
          <p>
            <FormattedMessage {...messages.followUsOn} />
          </p>
          <div className="social-links">
            <SocialMediaIcons.Facebook />
            <SocialMediaIcons.Twitter />
            <SocialMediaIcons.LinkedIn />
          </div>
        </div>
      </Styled.Social>
    );
  }

  return (
    <Styled.Container>
      <Styled.Links>
        {renderCopyrightInfo()}
        {renderSocialLinks()}
      </Styled.Links>
      <Styled.Copyright>
        <div className="footer-description">
          <Typography variant="body1" as="p" fontWeight="medium">
            <FormattedMessage {...messages.descriptionMain} />
          </Typography>
          <Typography variant="body1" as="p" fontWeight="medium">
            <FormattedMessage {...messages.descriptionMain1} />
          </Typography>
          <Typography variant="body1" as="p">
            <FormattedMessage {...messages.description} />
          </Typography>
        </div>
        <p className="footer-bottom">
          <FormattedMessage {...messages.descriptionSmall} />
        </p>
      </Styled.Copyright>
    </Styled.Container>
  );
}

export default HomepageFooter;
