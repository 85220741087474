export const LISTING_EVENTS = {
  START: 'start_listing',
  CREATE: 'create_listing',
  UPDATE_SELLER_DETAILS: 'update_listing_seller_details',
  CONTNIUE_LAST: 'continue_last_listing',
  DELETE_AND_START_NEW: 'delete_and_start_new_listing',
  CHECK_STATUS: 'check_listing_status',
  UPDATE_PROPERTY_TYPE: 'update_listing_property_type',
  UPDATE_PROPERTY_DETAILS: 'update_listing_property_details',
  UPDATE_PRICE_DETAILS: 'update_listing_price_details',
  ACCEPT_AGREEMENT: 'accept_listing_agreement',
  REQUEST_CALLBACK: 'request_listing_callback',
  SUBMIT: 'submit_listing',
  OPEN_CHATBOT: 'open_listing_chatbot',
};

export const HOME_VALUATION_EVENTS = {
  START: 'start_home_valuation',
  SUBMIT: 'submit_valuation',
  SUBMIT_WITHOUT_AVM_DATA: 'submit_valuation_without_avm_data',
};

export const GENERIC_EVENTS = {
  CALL_EXPERT: 'call_expert',
};
