export const media = {
  logo: '/static/logo-richr-new-colored.svg',
  logoWhite: '/static/logo-richr-white-text.svg',
  phone: '/static/icons/phone.svg',
  hamburger: '/static/images/home/hamburger.svg',
  rightArrow: '/static/icons/phone.svg',
};

export const CALL_EXPERT_LINK = 'tel:+18449574247';

export const SELLER_LISTING_ROUTE = '/seller/listing/';
