import { defineMessages } from 'react-intl';

export const scope = 'app.components.homepage-footer';

export default defineMessages({
  descriptionMain: {
    id: `${scope}.descriptionMain`,
    defaultMessage:
      '*Richr Inc and Richr Money LLC are not banks. Saving accounts are held with a reputable FDIC member financial institution. Funds are FDIC insured with the savings account, subject to applicable limitations and restrictions when we receive the funds deposited to your account. Annual Percentage Yields (APY) are variable and may be subject to change based on the market environment and other economic factors.',
  },
  descriptionMain1: {
    id: `${scope}.descriptionMain1`,
    defaultMessage:
      "Pay nothing upfront, just 1% of your home's selling price at closing. A minimum fee of $4,000 applies.",
  },
  copyright: {
    id: `${scope}.copyright`,
    defaultMessage: '© Copyright 2023. Richr Inc.',
  },
  allRightReserved: {
    id: `${scope}.allRightReserved`,
    defaultMessage: 'ALL RIGHTS RESERVED.',
  },
  followUsOn: {
    id: `${scope}.followUsOn`,
    defaultMessage: 'Follow us on',
  },
  about: {
    id: `${scope}.about`,
    defaultMessage: 'About',
  },
  manifesto: {
    id: `${scope}.manifesto`,
    defaultMessage: 'Manifesto',
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: 'FAQ',
  },
  termsAndPrivacy: {
    id: `${scope}.termsAndPrivacy`,
    defaultMessage: 'Terms & Privacy',
  },
  termsAndService: {
    id: `${scope}.termsAndService`,
    defaultMessage: 'Terms of Service',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  aba: {
    id: `${scope}.aba`,
    defaultMessage: 'Affiliated Business Disclosure',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Investment accounts are SIPC insured. The decision to invest in any investment account should be made after consulting with your financial advisor, tax advisor and attorney. Richr Money LLC and Richr Homes are affiliated businesses and therefore have a vested interest in the success of both businesses and the reduction associated with this opportunity. We encourage potential investors to ask questions and seek counsel before making any investment decisions. All investments are risky and subject to loss. Additionally, the answers provided to the risk assessment questions may directly affect the products suggested by Richr Money LLC for investment. We encourage potential investors to take their time when answering these questions to ensure the best fit for their individual financial goals and risk tolerance. Additional information about Richr Money can be found in its Form ADV Parts 2A, 2B and 3 (CRS), available upon request.',
  },
  descriptionSmall: {
    id: `${scope}.descriptionSmall`,
    defaultMessage:
      'All information provided is deemed reliable but is not guaranteed and should be independently verified. You should consult your own professionals for financial, legal, or tax advice and conduct your own due diligence. Brokerage services are provided by Richr MP Holdings LLC DBA Richr Homes, FL License # 1060165. Services of Richr affiliates, Richr Homes, Richr Money LLC, Richr Insurance LLC and Richr Title LLC, are optional.',
  },
});
